<template>
	<div>
		<h5>AlarmLogging</h5>
	</div>
</template>

<script>
	export default {
		name: "AlarmLogging",
	};
</script>

<style scoped></style>
